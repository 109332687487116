/*
import React, { useState, useEffect } from 'react';
import Layout from "../components/Layout";
import weekData from "../data/weekData.json";
import templateData from "../data/scheduleTemplates.json";
*/
/* eslint no-use-before-define: 0 */  // --> OFF
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import Layout from "../components/Layout";
import CheckboxLogger from '../components/CheckboxLogger';  // Import the CheckboxLogger component
//import { Link } from 'react-router-dom';
import CheckedItemsRenderer from '../components/CheckedItemsRenderer';
import weekData from "../data/schedule_system/output.json";
import templateData from "../data/schedule_system/scheduleTemplates.json";
import PageVisitCounter from '../components/pageview';
import Modal from '../components/Modal';
import "../style.css";
import "../media.css";
import Clock from '../components/clockUpdate';
import PeriodRenderer from '../components/PeriodRenderer';
import { CountdownCircleTimer } from 'react-countdown-circle-timer' 
//importing the pepfest schedule pic
import pepfest_schedule from "../data/pepfest.png";





import { Link } from 'react-router-dom';

function Dashboard() {





  //GET DATE
  const date = new Date();

  const yearNum = date.getFullYear();
  const monthNum = date.getMonth() + 1; //months start at 0
  const dateNum = date.getDate();


  //const secondNum = date.getSeconds()


  var finalYearString = "";
  var finalMonthString = "";
  var finalDateString = "";


  //handle year formatting
  finalYearString = String(yearNum)


  //handle month formatting
  if (monthNum < 10) {
    finalMonthString = "0" + String(monthNum);
  } else finalMonthString = String(monthNum);


  //handle date formatting
  if (dateNum < 10) {
    finalDateString = "0" + String(dateNum);
  } else finalDateString = String(dateNum);




  //final date
  const formattedDate = finalYearString + "-" + finalMonthString + "-" + finalDateString;







  //get date data
  const isDayOff = weekData[formattedDate]["isDayOff"];
  var scheduleTemplate = String(weekData[formattedDate]["schedule"]);
  if (isDayOff) {
    scheduleTemplate = "a0";
  }

  const x = scheduleTemplate[0];
  const y = scheduleTemplate[1];

  var totalData = templateData[x][y];

  //example on how - reference different periods
  const periodData = totalData["periods"];


  const per1 = periodData["1"];
  const per2 = periodData["2"];
  const per3 = periodData["3"];


  //lunch a
  //const per4a = periodData["4a"][0];
  //const lunch4a = periodData["4a"][1];


  //lunch b (split lunch)
  //const per4b_part1 = periodData["4b"][0];
  //const lunch4b = periodData["4b"][1];
  //const per4b_part2 = periodData["4b"][2];


  //lunch c (last lunch)
  //const per4c = periodData["4c"][1];
  //const lunch4c = periodData["4c"][0];


  const per5 = periodData["5"];
  const per6 = periodData["6"];


  //anchor time
  const anchorTime = periodData["AnchorTime"];
  const anchorIndex = totalData["AnchorIndex"];
  const periodNames = Object.keys(periodData);

  //next periods 
  const findNextPeriodKey = (key) => {
    for (let i = 0; i < periodNames.length; i++) {
      if (key === periodNames[i]) {
        try {
          return (String(periodNames[i + 1]));
        } catch {
          return ("");
        }
      }
    }
  }

  //GET CHECKBOX STATES AND KEEP THEM IN THE SEARCH BAR
  const [checkboxStates, setCheckboxStates] = useState([false, false, false]);

  //on loading the page for the first time, init checkbox states
  useEffect(() => {

    const searchParams = new URLSearchParams(window.location.search);
    //override the URL storage of the lunch ids if one is already stored in local storage
    if (localStorage.getItem('lunch') === "c") {
      setCheckboxStates([true, false, false]);
      searchParams.set('lunch', "c");
    } else if (localStorage.getItem('lunch') === "b") {
      setCheckboxStates([false, true, false]);
      searchParams.set('lunch', "b");
    } else if (localStorage.getItem('lunch') === "a") {
      setCheckboxStates([false, false, true]);
      searchParams.set('lunch', "a");
    }else {

      //if there isnt a matched lunch, go ahead and continue by loading from the url
      const checkboxStatesParam = searchParams.get('lunch');
  
  
      if (checkboxStatesParam !== null) {
        if (checkboxStatesParam === "c") {
          setCheckboxStates([true, false, false]);
        }
        else if (checkboxStatesParam === "b") {
          setCheckboxStates([false, true, false]);
        }
        else if (checkboxStatesParam === "a") {
          setCheckboxStates([false, false, true]);
        }
        //if theres no url lunch selected, then ggwp no lunch is selected
        else {
          setCheckboxStates([false, false, false]);
        }
      }
    }


  }, []);

  //on lunch select, change the stored lunch
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (checkboxStates[0]) {
      searchParams.set('lunch', "c");
      localStorage.setItem('lunch', "c");
    }
    else if (checkboxStates[1]) {
      searchParams.set('lunch', "b");
      localStorage.setItem('lunch', "b");
    }
    else if (checkboxStates[2]) {
      searchParams.set('lunch', "a");
      localStorage.setItem('lunch', "a");
    }
    window.history.replaceState(null, '', `?${searchParams.toString()}`);
  }, [checkboxStates]);

  //handle checkbox changes
  const handleCheckboxChange = (index) => {
    setCheckboxStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });

    //adding code to cause it to auto deselect if you select more then one checkbox, its a quick fix before we change over to radio buttons
    if (index === 0) {
      setCheckboxStates([true, false, false]);
    } else if (index === 1) {
      setCheckboxStates([false, true, false]);
    } else {
      setCheckboxStates([false, false, true]);
    }
  };

  //displayTimeStr
  const DTS = (timeStr) => {

    if (timeStr === undefined) {
      return "";
    }


    if (String(timeStr).length < 2) {
      return "0";
    }


    var strCopy = timeStr.split(':');
    var hr = parseInt(strCopy[0])

    if (hr > 12) {
      hr -= 12;
    }

    return (String(hr) + ':' + strCopy[1]);
  }

  const subtractTimeString = (timeStr1, timeStr2) => {
    var t1 = convertTime(0, 0, 0, timeStr1)[1];
    var t2 = convertTime(0, 0, 0, timeStr2)[1];

    const finalTime = Math.abs(t1 - t2);

    return (convertTime(0, 0, finalTime, "")[1]);
  }

  //add anchor time rendering
  // eslint-disable-next-line
  const renderMainHourBlock = (blockKey) => {
    //when blockKey === 0, theres either no class, or its the first run of this function
    if (blockKey === 0) {
      blockKey = determineCurrentPeriod()[0][0];
    }

    //is day off render option
    if (isDayOff === true) {
      return (<div class="text">No class today</div>);
    }

    //catching edge case where B week wed anchor time overlaps with first hour of lunch
    if (blockKey.length > 1) {
      ////check if there are several blocks contained within blockkey, and if they match up to AnchorTime and 4c
      if (blockKey[0] === "AnchorTime") {
        //if lunch is 4c, render like lunch would
        if (checkboxStates[0]) {
          console.log("hehehe");
          blockKey = "4c";
        }
        //otherwise, render like anchor time would
        else {
          blockKey = ["AnchorTime"];
        }
      }
    }
    //changing the end of the current period to 11:15 if schedule "b2" and lunch 4c and period is 3
    var isOverlappingEdgeCase = false;
    if (scheduleTemplate === "b2" && blockKey[0] === "AnchorTime" && checkboxStates[0] === true) {
      isOverlappingEdgeCase = true;
    }
    else {
      isOverlappingEdgeCase = false;
    }

    // const nextPeriodStartStr = periodData[nextBlockKey][0];
    const currTimeString = convertTime(time[0], time[1], 0, "")[2];
    const currTimeInMinutes = convertTime(time[0], time[1], 0, "")[1];
    // const timeTillNextHour = subtractTimeString(currTimeString, nextPeriodStartStr);

    const blockPeriodIndex = parseInt(blockKey);
    var lastIsAnchor = false;
    var nextIsAnchor = false;

    //if this key has an expected anchor time attatchment
    if (blockPeriodIndex === anchorIndex) {

      if (scheduleTemplate[0] === "a") {
        nextIsAnchor = true;
      }
      if (scheduleTemplate[0] === "b") {
        lastIsAnchor = true;
      }
    }

    const render = (pername, currTimeString, endOfPeriodTimeString) => {
      if (lastIsAnchor) {
        return (
          <div>
            <div class="text">This class ends in: {subtractTimeString(currTimeString, endOfPeriodTimeString)} {subtractTimeString(currTimeString, endOfPeriodTimeString) === 1 ? 'minute' : 'minutes'}</div>
            {/*<div class="text">Period: {pername}</div>
            <div class="text">At {DTS(endOfPeriodTimeString)}</div>
            <div class="text">Anchor Time from {DTS(anchorTime[0])} - {DTS(anchorTime[1])}</div>*/}
          </div>
        );
      }
      if (nextIsAnchor) {
        return (
          <div>
            <div class="text">This class ends in: {subtractTimeString(currTimeString, endOfPeriodTimeString)} {subtractTimeString(currTimeString, endOfPeriodTimeString) === 1 ? 'minute' : 'minutes'}</div>
          </div>
        );
      }
      return (
        <div>
          <div class="text">This class ends in: {subtractTimeString(currTimeString, endOfPeriodTimeString)} {subtractTimeString(currTimeString, endOfPeriodTimeString) === 1 ? 'minute' : 'minutes'}</div>
        </div>
      )
    }


    if (blockKey[0] === "AnchorTime") {
      //wild wacky edge case fix
      if (isOverlappingEdgeCase) {
        const endTimeMins = convertTime(0, 0, 0, anchorTime[1])[1];
        const endTimeString = convertTime(0, 0, endTimeMins, "")[2];
        return (render(blockKey, currTimeString, endTimeString));
      }
      else {
        return (render(blockKey, currTimeString, anchorTime[1]));
      }
    }
    else {


      switch (blockPeriodIndex) {
        case 1:
          return (render(blockKey, currTimeString, per1[1]));
        case 2:
          return (render(blockKey, currTimeString, per2[1]));
        case 3:
          return (render(blockKey, currTimeString, per3[1]));

        case 4:
          return (<div class="text"><CheckedItemsRenderer checkboxStates={checkboxStates} showAdditionalContent={true} timeslot={false} currTimeList={time} /></div>);
        case 5:
          return (render(blockKey, currTimeString, per5[1]));
        case 6:
          return (render(blockKey, currTimeString, per6[1]));
        default:
          if ((currTimeInMinutes > (8 * 60)) && (currTimeInMinutes < (14 * 60) + 40)) {
            return (
              <div class="text">Currently passing time</div>
            );
          }
          return (
            <div class="text"> Currently No Period</div> //{8 * 60} {currTimeInMinutes} {(14 * 60) + 40}
          );
      }
    }
  }

  //add anchor time rendering
  const renderScheduleBlock = (blockKey) => {
    //is day off render option
    if (isDayOff === true) {
      return (<div class="text">N/A</div>);
    }
    //console.log("_____")
    //console.log("current period "+parseInt(currPeriod[0]))
    //console.log("block key "+parseInt(blockKey))
    //console.log("_____")
    const blockPeriodIndex = parseInt(blockKey);
    var lastIsAnchor = false;
    var nextIsAnchor = false;

    //if this key has an expected anchor time attatchment
    if (blockPeriodIndex === anchorIndex) {

      if (scheduleTemplate[0] === "a") {
        nextIsAnchor = true;
      }
      if (scheduleTemplate[0] === "b") {
        lastIsAnchor = true;
      }
    }

    var currentrender;
    if (parseInt(currPeriod[0]) === blockPeriodIndex) {
      currentrender = true;
    } else {
      currentrender = false;
    }

    //rendering function
    const render = (pername, period_to_use) => {
      if (lastIsAnchor) {
        return (
          <div>
            <div className={`text ${currentrender ? 'currentperiod' : ''}`}>Period {pername}: {DTS(period_to_use[0])} - {DTS(period_to_use[1])}</div>
            <div class="text anchorperiod">Anchor Time: {DTS(anchorTime[0])} - {DTS(anchorTime[1])}</div>
          </div>
        );
      }
      if (nextIsAnchor) {
        return (
          <div>
            <div className={`text ${currentrender ? 'currentperiod' : ''}`}>Period {pername}: {DTS(period_to_use[0])} - {DTS(period_to_use[1])}</div>
            <div class="text anchorperiod">Anchor Time: {DTS(anchorTime[0])} - {DTS(anchorTime[1])}</div>
          </div>
        );
      }
      return (
        <div className={`text ${currentrender ? 'currentperiod' : ''}`}>Period {pername}: {DTS(period_to_use[0])} - {DTS(period_to_use[1])}</div>
      );
    }




    //if this key is anchor time
    if (blockKey[0] === "AnchorTime") {
      //skip this key for the next key
      return (renderScheduleBlock(findNextPeriodKey(blockKey)));
    }
    else {
      switch (blockPeriodIndex) {
        case 1:
          return (render(blockKey, per1));
        case 2:
          return (render(blockKey, per2));
        case 3:
          return (render(blockKey, per3));
        case 4:
          if (lastIsAnchor) {
            //wacky weird edge case detection goes here
            if (scheduleTemplate === "b2" && checkboxStates[0] === true) {

              const endTimeMins = convertTime(0, 0, 0, anchorTime[1])[1];
              const endTimeString = convertTime(0, 0, endTimeMins, "")[2];

              return (
                <div>
                  <div className={`text ${currentrender ? 'currentperiod' : ''}`}><CheckedItemsRenderer checkboxStates={checkboxStates} showAdditionalContent={false} timeslot={true} /></div>
                  <div class="text anchorperiod">Anchor Time: {DTS(anchorTime[0])} - {DTS(endTimeString)}</div>
                </div>
              );
            }
            else {
              return (
                <div>
                  <div className={`text ${currentrender ? 'currentperiod' : ''}`}><CheckedItemsRenderer checkboxStates={checkboxStates} showAdditionalContent={false} timeslot={true} /></div>
                  <div class="text anchorperiod">Anchor Time: {DTS(anchorTime[0])} - {DTS(anchorTime[1])}</div>
                </div>
              );
            }
          }
          if (nextIsAnchor) {
            return (
              <div>
                <div class="text"><CheckedItemsRenderer checkboxStates={checkboxStates} showAdditionalContent={false} timeslot={true} /></div>
                <div className={`text ${currentrender ? 'currentperiod' : ''}`}>Anchor Time: {DTS(anchorTime[0])} - {DTS(anchorTime[1])}</div>
              </div>
            );
          }
          return (
            <div className={`text ${currentrender ? 'currentperiod' : ''}`}><CheckedItemsRenderer checkboxStates={checkboxStates} showAdditionalContent={false} timeslot={true} /></div>
          );
        case 5:
          return (render(blockKey, per5));
        case 6:
          return (render(blockKey, per6));
        default:
          return (
            <div class="text"></div>
          );
      }
    }
  }

  //convert time formats
  const convertTime = (_hr, _min, _total_min, _timeStr) => {
    var hr = _hr;
    var min = _min;
    var totalMin = _total_min;
    var timeStr = _timeStr;
    var minStr;
    if (!(hr === 0 && min === 0)) {
      //convert hr, min - total minutes
      totalMin = (hr * 60) + min;


      //convert hr, min - time string
      minStr = String(min)
      if (min < 10) {
        minStr = "0" + minStr;
      }
      timeStr = String(hr) + ":" + minStr;

    }
    else if (!(totalMin === 0)) {
      //convert total Min - hr, min
      hr = Math.floor(totalMin / 60);
      min = totalMin % 60;

      //convert hr, min - time string 
      minStr = String(min)
      if (min < 10) {
        minStr = "0" + minStr;
      }
      timeStr = String(hr) + ":" + minStr;
    }
    else if (!(timeStr === "")) {
      //convert timeStr - hr, min
      var strCopy = timeStr.split(':')
      hr = parseInt(strCopy[0]);
      min = parseInt(strCopy[1]);

      //convert hr, min - totalMin
      totalMin = (hr * 60) + min;
    }
    return [[hr, min], totalMin, timeStr];
  }

  //use an Effect - update the rendering every second
  const [currPeriod, setCurrPer] = useState(0);
  // eslint-disable-next-line
  const [lastPeriodIndex, setLastPeriodIndex] = useState(0);
  //const [nextPeriodIndex, setnextPeriodIndex] = useState(0);


  const currentTime = new Date();
  const [time, setTime] = useState([currentTime.getHours(), currentTime.getMinutes()]);


  const currentDate = currentTime.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  const [currentDateState] = useState(currentDate);

  //this is currently never used so I've just commented it entirely out
  //const [isAM, setisAM] = useState(0);

  const [secondsSinceSetLastPeriod, setSecondsSinceSetLastPeriod] = useState(0);

  //This is the function that returns each time
  // eslint-disable-next-line
  const findCurrPeriod = () => { //if you want to debug setTime stuff, you need to disable useCallback(() => {
    //get the current hours, minutes, seconds
    const currentTime = new Date();
    var hour = currentTime.getHours();
    var minute = currentTime.getMinutes();

    
    //for debugging if times are working

    // hour = time[0];
    // minute = time[1] + 1;

    // hour = 11;
    // minute = 12;


    if (minute >= 60) {
      hour += 1;
      minute -= 60;

    }
    // if (hour >= 15) {
    //     hour -= 8;
    // }

    setTime([hour, minute]);


    //find out AM/PM
    //if (hour < 12) setisAM(true);
    //else setisAM(false);
    return (determineCurrentPeriod());
    // eslint-disable-next-line
  }



  const determineCurrentPeriod = () => {
    //get current period's name, start & end time
    const periodDataKeys = Object.keys(periodData);
    let currPeriodNames = [];


    const currTimeInMinutes = convertTime(time[0], time[1], 0, "")[1];

    var start = currTimeInMinutes;
    var end = 0;
    let withinPeriod = false;

    for (let i = 0; i < periodDataKeys.length; i++) {
      const currPeriodKey = periodDataKeys[i];
      const currPeriodData = periodData[currPeriodKey];

      var numPeriods = 0;
      //if it is a stand alone period
      if ((currPeriodData.length === 2) && (currPeriodData[0].length > 3)) {

        //set the number of contained periods - 1
        numPeriods = 1;
      }
      else {

        //set the number of contained periods - however many are contained
        numPeriods = currPeriodData.length;
      }

      var startTime;
      var endTime;
      //get start and end time of the period
      if (numPeriods === 1) {
        startTime = convertTime(0, 0, 0, currPeriodData[0])[1];
        endTime = convertTime(0, 0, 0, currPeriodData[1])[1];
      }
      else if (numPeriods >= 2) {
        startTime = convertTime(0, 0, 0, currPeriodData[0][0])[1];
        endTime = convertTime(0, 0, 0, currPeriodData[numPeriods - 1][1])[1];
      }

      //find out if you are currently within the start and endtimes
      withinPeriod = false;

      //hour is >= start hour and <= endHour
      if (startTime <= currTimeInMinutes && currTimeInMinutes < endTime) {
        withinPeriod = true;
      }


      //if within the period, add its contents - the list of things - render
      if (withinPeriod) {
        currPeriodNames.push(currPeriodKey);


        //find the current start time in minutes
        //if (!isAM) currStartTimeInMins += 12 * 60;


        //check if the current start time is the earliest start time seen yet
        if (startTime < start) {
          start = startTime;
        }


        //find the current end time in minutes
        //if (!isAM) currStartEndInMins += 12 * 60;

        //check if the current start time is the earliest start time seen yet
        if (endTime > end) {
          end = endTime;
        }
      }
    }

    let remainingString = "";

    // if (withinPeriod) {
    let timeRemaining = Math.abs(end - currTimeInMinutes);
    let hoursRemaining = Math.floor(timeRemaining / 60);
    let minutesRemaining = timeRemaining % 60;
    const remainingMinsString = minutesRemaining > 0 ? `${minutesRemaining}${minutesRemaining > 1 ? " mins" : " min"} ` : '0 mins';
    const remainingHoursString = hoursRemaining > 0 ? `${hoursRemaining}${hoursRemaining > 1 ? " hrs & " : " hr & "} ` : '';
    remainingString = `\u2022 ${remainingHoursString}${remainingMinsString} left`;
    // }

    const startTimeOutput = convertTime(0, 0, start, "")[2];
    const endTimeOutput = convertTime(0, 0, end, "")[2];

    //if the length is 0 its either passing time or school is not in Period
    if (currPeriodNames.length === 0) currPeriodNames.push("- Passing time");

    return ([currPeriodNames, startTimeOutput, endTimeOutput, remainingString]);
  }

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      const currPer = findCurrPeriod();
      setCurrPer(currPer);
      //var currentId;
      if (currPer[0] !== "passing time") {
        //currentId = 
        setLastPeriodIndex(currPer[0]);
        setSecondsSinceSetLastPeriod(0);
        console.log("current period " + currPer);
        //console.log("NOT PASSING TIME");
        //setnextPeriodIndex(currPer[0]);
      }
      else setSecondsSinceSetLastPeriod(secondsSinceSetLastPeriod + 1);

      if (currPer[0] === "passing time" && secondsSinceSetLastPeriod > (5 * 60 * 0)) {
        //currentId = 
        setLastPeriodIndex(0);
        setSecondsSinceSetLastPeriod(0);
      }


      //current time metric
      // const currentTime = new Date();
      // setTime([currentTime.getHours(), currentTime.getMinutes()]);
      //for debugging if times are working, comment the line above and go down - the findCurrPeriod funct, add in this line             setTime([hour, minute]);
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [findCurrPeriod, secondsSinceSetLastPeriod, currPeriod]);

  // {renderMainHourBlock(renderCurrentPeriod(lastPeriodIndex)[0], renderNextPeriod(nextPeriodIndex)[0])}
  // {renderScheduleBlock(renderNextPeriod(nextPeriodIndex)[0])}
  // setTime([time[0], time[1]]);
  const RenderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      //return <center className="countdown_font">Period Has Ended</center>;
    }

    return (
      <center className="countdown_font">
        {renderMainHourBlock(lastPeriodIndex)}
      </center>
    );
  };
  function convertTimeToMinutes(timeString) {
    // Extract hours and minutes using regular expressions
    const hoursMatch = timeString.match(/(\d+)\s*hr/);
    const minutesMatch = timeString.match(/(\d+)\s*min/);

    // Convert hours and minutes to minutes
    let totalMinutes = 0;
    if (hoursMatch) {
        totalMinutes += parseInt(hoursMatch[1]) * 60;
    }
    if (minutesMatch) {
        totalMinutes += parseInt(minutesMatch[1]);
    }

    return totalMinutes;
}

  function extractMinutes(timeString) {

    
    let firstFourLetters = timeString.substring(0, 6);
    let isOneHour = firstFourLetters === "• 1 hr";
    console.log(timeString)
    console.log(isOneHour)
    if (isOneHour){
      return convertTimeToMinutes(timeString)
    }

    if (timeString === "Currently passing time") {
      return 5;
    }
    if (timeString === "passing time") {
      return 5;
    }
    
    // Split the string by space
    const parts = timeString.split(' ');

    // Get the second part, which should be the number of minutes
    const minutesString = parts[1];

    // Convert the string to an integer
    const minutes = parseInt(minutesString);

    // Return the integer value

    return minutes;
  }



  //add time calculation for the circle timer thingy
  const findCircleTimerData = (blockKey) => {
    //defining a helper funct to make this easier to code
    //returns [start time, stop time, minutes till end of hour]
    const get_lunch = (indx, currTimeList) => {
      var index = parseInt(indx[6]);
    
      //GET DATE
      const date = new Date();
    
      const yearNum = date.getFullYear();
      const monthNum = date.getMonth() + 1; //months start at 0
      const dateNum = date.getDate();
    
    
      //const secondNum = date.getSeconds()
    
    
      var finalYearString = "";
      var finalMonthString = "";
      var finalDateString = "";
    
    
      //handle year formatting
      finalYearString = String(yearNum)
    
    
      //handle month formatting
      if (monthNum < 10) {
        finalMonthString = "0" + String(monthNum);
      } else finalMonthString = String(monthNum);
    
    
      //handle date formatting
      if (dateNum < 10) {
        finalDateString = "0" + String(dateNum);
      } else finalDateString = String(dateNum);
    
    
    
    
      //final date
      const formattedDate = finalYearString + "-" + finalMonthString + "-" + finalDateString;
    
    
      //get date data
      //const isDayOff = String(weekData[formattedDate]["isDayOff"]);
      const scheduleTemplate = String(weekData[formattedDate]["schedule"]);
    
    
      const x = scheduleTemplate[0];
      const y = scheduleTemplate[1];
    
    
      var totalData = templateData[x][y];
    
      //example on how to reference different periods
      const periodData = totalData["periods"];
    
      //const per5 = periodData["5"];
    
    
      //period 4 lunches time list
      const per4a = periodData["4a"];
      const per4b = periodData["4b"];
      const per4c = periodData["4c"];
    
      //find the current time
      const hours = currTimeList[0];
      const mins = currTimeList[1];
    
    
    
      //find the overlapping hour
      const currentTimeInMinutes = convertTime(hours, mins, 0, "")[1];
    
      const findOverlappingHour = (lunchPeriod) => {
        //for each [start, stop], 
        for (let i = 0; i < lunchPeriod.length; i++) {
          const startStr = lunchPeriod[i][0];
          const stopStr = lunchPeriod[i][1];
    
          const startTime = convertTime(0, 0, 0, startStr)[1];
          const stopTime = convertTime(0, 0, 0, stopStr)[1];
    
          //if this is the contained hour
          if (startTime - 5 <= currentTimeInMinutes && currentTimeInMinutes <= stopTime) {
    
            //if its actually, currently this hour and not passing time
            if (startTime <= currentTimeInMinutes && currentTimeInMinutes <= stopTime) {
              //if there is another hour after this within the lunch period
              if (i + 1 <= lunchPeriod.length - 1) {
                return ([i, true, false]);
              }
              else {
                return ([i, false, false]);
              }
            }
            //otherwise if its passing time, return that it is
            else {
              if (i + 1 <= lunchPeriod.length - 1) {
                return ([i, true, true]);
              }
              else {
                return ([i, false, true]);
              }
            }
    
          }
        }
      }
    
      //subtractTimeStrings
      const currTimeString = convertTime(hours, mins, 0, "")[2];
      const subtractTimeString = (timeStr1, timeStr2) => {
        var t1 = convertTime(0, 0, 0, timeStr1)[1];
        var t2 = convertTime(0, 0, 0, timeStr2)[1];
    
        const finalTime = Math.abs(t1 - t2);
    
        return (convertTime(0, 0, finalTime, "")[1]);
      }
    
      //lunch A (last lunch), index === 3
      if (index === 1) {
        //calculations
        const out = findOverlappingHour(per4a);
        const currHourIndexWithinTheLunch = out[0];
        const isAnotherLunchHour = out[1];
    
        //rendering if passing time
        if (out[2]) {
          return (
            [0, 0, 0]
          )
        }
        //this code is not needed anymore, but its good to have one copy laying around so that its really easy to reimplement
        // var nextHourStartString = "";
        // if (isAnotherLunchHour) {
        //   nextHourStartString = per4a[currHourIndexWithinTheLunch + 1][0];
        // }
        // else {
        //   nextHourStartString = per5[0];
        // }
    
        var nextHourStartString = "";
        if (isAnotherLunchHour) {
          nextHourStartString = per4a[currHourIndexWithinTheLunch][1];
        }
        else {
          nextHourStartString = per4a[1][1];
        }
    
        const timeTillNextHour = subtractTimeString(currTimeString, nextHourStartString);
    
        //const currHourStart = per4a[currHourIndexWithinTheLunch][0];
        //const currHourStop = per4a[currHourIndexWithinTheLunch][1];
    
        //render
        return (
          //start, stop, time_left
          [convertTime(0, 0, 0, per4a[currHourIndexWithinTheLunch][0])[1], convertTime(0, 0, 0, nextHourStartString)[1], timeTillNextHour]
        );
      }
      //lunch B (split lunch)
      else if (index === 2) {
        //calculations
        const out = findOverlappingHour(per4b);
        const currHourIndexWithinTheLunch = out[0];
        const isAnotherLunchHour = out[1];
    
        //rendering if passing time
        if (out[2]) {
          return (
            [0, 0, 0]
          );
        }
    
    
        nextHourStartString = "";
        if (isAnotherLunchHour) {
          nextHourStartString = per4b[currHourIndexWithinTheLunch][1];
        }
        else {
          nextHourStartString = per4b[currHourIndexWithinTheLunch][1];
        }
        const timeTillNextHour = subtractTimeString(currTimeString, nextHourStartString);
    
        //const currHourStart = per4b[currHourIndexWithinTheLunch][0];
        //const currHourStop = per4b[currHourIndexWithinTheLunch][1];
    
    
        //render
        return (
          //start, stop, time_left
          [convertTime(0, 0, 0, per4b[currHourIndexWithinTheLunch][0])[1], convertTime(0, 0, 0, nextHourStartString)[1], timeTillNextHour]
        );
      }
      //lunch C, aka first lunch
      else if (index === 3) {
        const out = findOverlappingHour(per4c);
        const currHourIndexWithinTheLunch = out[0];
        const isAnotherLunchHour = out[1];
    
    
        //rendering if passing time
        if (out[2]) {
          return (
            [0, 0, 0]
          )
        }
    
        nextHourStartString = "";
        if (isAnotherLunchHour) {
          nextHourStartString = per4c[currHourIndexWithinTheLunch][1];
        }
        else {
          nextHourStartString = per4c[1][1];
        }
        const timeTillNextHour = subtractTimeString(currTimeString, nextHourStartString);
    
        //const currHourStart = per4c[currHourIndexWithinTheLunch][0];
        //const currHourStop = per4c[currHourIndexWithinTheLunch][1];
    
    
        //render
        return (
          //start, stop, time_left
          [convertTime(0, 0, 0, per4c[currHourIndexWithinTheLunch][0])[1], convertTime(0, 0, 0, nextHourStartString)[1], timeTillNextHour]
        );
      }
    }




    //when blockKey === 0, theres either no class, or its the first run of this function
    if (blockKey === 0) {
      blockKey = determineCurrentPeriod()[0][0];
    }

    //is day off render option
    if (isDayOff === true) {
      return ([0, 0, 0]);
    }

    //catching edge case where B week wed anchor time overlaps with first hour of lunch
    if (blockKey.length > 1) {
      ////check if there are several blocks contained within blockkey, and if they match up to AnchorTime and 4c
      if (blockKey[0] === "AnchorTime") {
        //if lunch is 4c, render like lunch would
        if (checkboxStates[0]) {
          blockKey = "4c";
        }
        //otherwise, render like anchor time would
        else {
          blockKey = ["AnchorTime"];
        }
      }
    }
    //changing the end of the current period to 11:15 if schedule "b2" and lunch 4c and period is 3
    var isOverlappingEdgeCase = false;
    if (scheduleTemplate === "b2" && blockKey[0] === "AnchorTime" && checkboxStates[0] === true) {
      isOverlappingEdgeCase = true;
    }
    else {
      isOverlappingEdgeCase = false;
    }

    // const nextPeriodStartStr = periodData[nextBlockKey][0];
    const currTimeString = convertTime(time[0], time[1], 0, "")[2];
    const currTimeInMinutes = convertTime(time[0], time[1], 0, "")[1];
    // const timeTillNextHour = subtractTimeString(currTimeString, nextPeriodStartStr);

    const blockPeriodIndex = parseInt(blockKey);


    if (blockKey[0] === "AnchorTime") {
      //wild wacky edge case fix
      if (isOverlappingEdgeCase) {
        const endTimeMins = convertTime(0, 0, 0, anchorTime[1])[1];

        const startTimeMins = convertTime(0, 0, 0, anchorTime[0])[1]
        const endTimeString = convertTime(0, 0, endTimeMins - 5, "")[2];

        //start, stop, mins_left
        return ([startTimeMins, endTimeMins, subtractTimeString(currTimeString, endTimeString)]);
      }
      else {
        return ([convertTime(0, 0, 0, anchorTime[0])[1], convertTime(0, 0, 0, anchorTime[1])[1], subtractTimeString(currTimeString, anchorTime[1])]);
      }
    }
    else {

      switch (blockPeriodIndex) {
        case 1:
          return ([convertTime(0, 0, 0, per1[0])[1], convertTime(0, 0, 0, per1[1])[1], subtractTimeString(currTimeString, per1[1])]);
        case 2:
          return ([convertTime(0, 0, 0, per2[0])[1], convertTime(0, 0, 0, per2[1])[1], subtractTimeString(currTimeString, per2[1])]);
        case 3:
          return ([convertTime(0, 0, 0, per3[0])[1], convertTime(0, 0, 0, per3[1])[1], subtractTimeString(currTimeString, per3[1])]);

        case 4:
          var lunchNum = 0;
          if (checkboxStates[0]) {
            lunchNum = 3;
          }
          else if (checkboxStates[1]) {
            lunchNum = 2;
          }
          else {
            lunchNum = 1;
          }
          return (get_lunch("lunch " + lunchNum.toString(),  time))
        case 5:
          return ([convertTime(0, 0, 0, per5[0])[1], convertTime(0, 0, 0, per5[1])[1], subtractTimeString(currTimeString, per5[1])]);
        case 6:
          return ([convertTime(0, 0, 0, per6[0])[1], convertTime(0, 0, 0, per6[1])[1], subtractTimeString(currTimeString, per6[1])]);
        default:
          if ((currTimeInMinutes > (8 * 60)) && (currTimeInMinutes < (14 * 60) + 40)) {
            return (
              [0,0,0]
            );
          }
          return (
            [0,0,0]
          );
      }
    }
  }



  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [time]);


  //special schedule rendering
  if (formattedDate === "2024-09-26" || formattedDate === "2024-09-27") {
    return (
      <img className="img-schedule" src={pepfest_schedule} alt={""}/>
    );
  }
  return (

    <shell>

      <Clock />


      <schedulebody>
        <nav className="nav-container">
          <div className="nav-title-container">
            <img src="logo.png" alt="LOGO" className="nav-logo" />
            <div className="nav-text-container">
              <p>Student Assist | Schedule App</p>
              <p>&#8213;</p>
              <p>Week {(scheduleTemplate[0].toUpperCase())}</p>
            </div>
          </div>
          <div className="nav-credits-container">
            <p>Made by Archie, Zeen, Dris, & Ren</p>
            
          </div>
        </nav>

        <main>
          <div id="main">
          <Modal />
            <div id="main-left">
              <div className="main-left-title-container">
                <p className="main-left-title">Hiya, Skipper! </p>
                {/* <div>DEBUG  {(extractMinutes(determineCurrentPeriod()[3]))}min {determineCurrentPeriod()[3]}</div> */}
                <p className="main-left-subtitle">{(currentDateState)}</p>
              </div>


              <div className='counter-container'>

                <CountdownCircleTimer
                  key={key}
                  size={500}
                  isPlaying = {false}
                  className="countdown-timer"
                  initialRemainingTime={((findCircleTimerData(lastPeriodIndex)[0] * 60) - (findCircleTimerData(lastPeriodIndex)[1] * 60)) - ((findCircleTimerData(lastPeriodIndex)[2] * 60) - (new Date().getSeconds()))}
                  duration={(findCircleTimerData(lastPeriodIndex)[0] * 60) - (findCircleTimerData(lastPeriodIndex)[1] * 60)}
                  colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                  colorsTime={[(extractMinutes(determineCurrentPeriod()[3])) * 60, (extractMinutes(determineCurrentPeriod()[3])) * 20, (extractMinutes(determineCurrentPeriod()[3])) * 10, 0]}
                  strokeWidth={30}
                >

                  {RenderTime}
                </CountdownCircleTimer>
              </div>


            </div>

            <div id="main-right">
              <PeriodRenderer currPeriod={currPeriod} periodNumber={1} renderScheduleBlock={renderScheduleBlock} />
              <PeriodRenderer currPeriod={currPeriod} periodNumber={2} renderScheduleBlock={renderScheduleBlock} />
              <PeriodRenderer currPeriod={currPeriod} periodNumber={3} renderScheduleBlock={renderScheduleBlock} />
              <PeriodRenderer currPeriod={currPeriod} periodNumber={4} renderScheduleBlock={renderScheduleBlock} />
              <PeriodRenderer currPeriod={currPeriod} periodNumber={5} renderScheduleBlock={renderScheduleBlock} />
              <PeriodRenderer currPeriod={currPeriod} periodNumber={6} renderScheduleBlock={renderScheduleBlock} />
            </div>
          </div>
          <footer>
            <div id="footer-settings">

              <h2 class="footer-title">Customization</h2>

              <p class="footer-subtitle">
                Enable extra hidden features to improve your experience!

              </p>

              <div class="footer-settings-section-container">
                <section>
                  <div class="footer-settings-container">
                    <ion-icon
                      class="footer-settings-icon"
                      id="footer-settings-icon-lunch"
                      name="pizza-outline"
                    ></ion-icon>
                    <p class="footer-settings-title">Lunch</p>

                  </div>
                  <CheckboxLogger class="footer-lunch-container" checkboxStates={checkboxStates} onCheckboxChange={handleCheckboxChange} />

                </section>

                <section>
                  <div class="footer-settings-container">
                    <ion-icon
                      class="footer-settings-icon"
                      id="footer-settings-icon-minimal"
                      name="create-outline"

                    >
                    </ion-icon>
                    <p class="footer-settings-title">Minimal</p>
                  </div>
                  <div class="footer-lunch-container">
                    <div class="footer-lunch">
                      <p class="footer-lunch-period">0th Period</p>
                      <p class="footer-lunch-detail">
                        Prior school hour (7:00 - 8:00)
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div id="footer-credits">
              <h2 class="footer-title">Credits</h2>

              <p class="footer-subtitle">
                Say hi to our awesome designers and developers!
              </p>

              <div class="footer-credit-section-container">
                <section class="footer-credit-section">
                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-credit-icon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M160 368L32 256l128-112M352 368l128-112-128-112M304 96l-96 320" /></svg>

                  <div class="verticalLine"></div>
                  <div>
                    <div>
                      <p>Developer</p>
                      <p class="footer-credit-title">Archie</p>
                    </div>
                    <p class="footer-credit-detail">
                      <div>Archie is a proficient developer specializing in JSX development and rendering systems, ensuring optimal code efficiency. He also excels in blog writing and effective communication.</div>
                    </p>
                  </div>
                </section>

                <section class="footer-credit-section">
                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-credit-icon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M160 368L32 256l128-112M352 368l128-112-128-112M304 96l-96 320" /></svg>

                  <div class="verticalLine"></div>
                  <div>
                    <div>
                      <p>Developer</p>
                      <p class="footer-credit-title">Zeen</p>
                    </div>
                    <p class="footer-credit-detail">
                      <div>Zeen is a skilled Python developer with expertise in data processing using JSON data systems. He specializes in seamless API integration and optimization.</div>
                    </p>
                  </div>
                </section>

                <section class="footer-credit-section">

                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-credit-icon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M160 368L32 256l128-112M352 368l128-112-128-112M304 96l-96 320" /></svg>
                  <div class="verticalLine"></div>
                  <div>
                    <div>
                      <p>Developer</p>
                      <p class="footer-credit-title">Dris</p>
                    </div>
                    <p class="footer-credit-detail">
                      <div>Dris is an experienced manager who excels in frontend planning and development. He has a strong background in cloud storage solutions and ensures seamless project coordination.</div>
                    </p>
                  </div>
                </section>

                <section class="footer-credit-section">

                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-credit-icon" viewBox="0 0 512 512"><path d="M452.37 59.63h0a40.49 40.49 0 00-57.26 0L184 294.74c23.08 4.7 46.12 27.29 49.26 49.26l219.11-227.11a40.49 40.49 0 000-57.26zM138 336c-29.88 0-54 24.5-54 54.86 0 23.95-20.88 36.57-36 36.57C64.56 449.74 92.82 464 120 464c39.78 0 72-32.73 72-73.14 0-30.36-24.12-54.86-54-54.86z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /></svg>

                  <div class="verticalLine"></div>
                  <div>
                    <div>
                      <p>Designer</p>
                      <p class="footer-credit-title">Ren</p>
                    </div>
                    <p class="footer-credit-detail">
                      <div>Ren is a talented designer specializing in CSS upgrades and frontend development. He creates visually appealing and user-friendly interfaces with cross-browser compatibility.</div>
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div class="footer-title"><PageVisitCounter /></div>
           
            <div id="footer-resources">
              <h2 class="footer-title">Resources</h2>
              <div class="footer-resources-container">
                <a
                  class="footer-resource"
                  href="https://forms.gle/ZMR65Tqj6B8SThY17"
                >

                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-resource-icon" viewBox="0 0 512 512"><path d="M370 378c28.89 23.52 46 46.07 46 86M142 378c-28.89 23.52-46 46.06-46 86M384 208c28.89-23.52 32-56.07 32-96M128 206c-28.89-23.52-32-54.06-32-94M464 288.13h-80M128 288.13H48M256 192v256" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M256 448h0c-70.4 0-128-57.6-128-128v-96.07c0-65.07 57.6-96 128-96h0c70.4 0 128 25.6 128 96V320c0 70.4-57.6 128-128 128z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M179.43 143.52a49.08 49.08 0 01-3.43-15.73A80 80 0 01255.79 48h.42A80 80 0 01336 127.79a41.91 41.91 0 01-3.12 14.3" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /></svg>

                  <p class="footer-resource-title">Bug Report</p>
                </a>
                <a
                  class="footer-resource"

                  href="https://parz.wiki/page16.html"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-resource-icon" viewBox="0 0 512 512"><path d="M256 160c16-63.16 76.43-95.41 208-96a15.94 15.94 0 0116 16v288a16 16 0 01-16 16c-128 0-177.45 25.81-208 64-30.37-38-80-64-208-64-9.88 0-16-8.05-16-17.93V80a15.94 15.94 0 0116-16c131.57.59 192 32.84 208 96zM256 160v288" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /></svg>


                  <p class="footer-resource-title">Blog</p>
                </a>
                <a
                  class="footer-resource"
                  href="https://resources.finalsite.net/images/v1691073135/minnetonka/vfvkky04sboeydjg6nqd/2024-25SchoolCalendar.pdf"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-resource-icon" viewBox="0 0 512 512"><rect x="48" y="80" width="416" height="384" rx="48" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M128 48v32M384 48v32M464 160H48M304 260l43.42-32H352v168M191.87 306.63c9.11 0 25.79-4.28 36.72-15.47a37.9 37.9 0 0011.13-27.26c0-26.12-22.59-39.9-47.89-39.9-21.4 0-33.52 11.61-37.85 18.93M149 374.16c4.88 8.27 19.71 25.84 43.88 25.84 28.59 0 52.12-15.94 52.12-43.82 0-12.62-3.66-24-11.58-32.07-12.36-12.64-31.25-17.48-41.55-17.48" /></svg>
                  <p class="footer-resource-title">24-25 CALENDAR</p>
                </a>
                <a
                  class="footer-resource"
                  href="https://resources.finalsite.net/images/v1692291901/minnetonka/n3dk3k9fohmt0zjgvllh/MHSDailySchedule.pdf"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-resource-icon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M320 120l48 48-48 48" /><path d="M352 168H144a80.24 80.24 0 00-80 80v16M192 392l-48-48 48-48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M160 344h208a80.24 80.24 0 0080-80v-16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /></svg>
                  <p class="footer-resource-title">MHS Daily Schedule</p>
                </a>
                <a
                  class="footer-resource"
                  href="https://resources.finalsite.net/images/v1692977209/minnetonka/z59i3lm8g4bzuwzfc5rw/2023-24-MHS-AB-Calendar.pdf"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-resource-icon" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 128v144h96" /></svg>
                  <p class="footer-resource-title">Time Calendar</p>
                </a>
                <a
                  class="footer-resource"
                  href='https://drive.google.com/drive/folders/1AEItip_o-d69NcfMZWMM1dZjjGwj28iU'           
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-resource-icon" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 128v144h96" /></svg>
                  <p class="footer-resource-title">IB/AP information</p>
                </a>
                <a
                  class="footer-resource"
                  href='https://linqconnect.com/public/menu/T7UFXN?buildingId=aa4c573d-ab02-ed11-a7e5-e5d7aa036fa5'
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="footer-resource-icon" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 128v144h96" /></svg>
                  <p class="footer-resource-title">School Lunch</p>
                </a>
              </div>
            </div>
            <h2>
            <Link to="/test" >TESTING</Link>
            _____
            <Link to="/test2" >TESTING2</Link>
            _____
            <Link to="/sign" >SIGN</Link>
            </h2>
          </footer>
        </main>
        <script src="test.js"></script>
        <script
          type="module"
          src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"
        ></script>
        <script
          src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"
        ></script>
      </schedulebody>
    </shell >
  );
}



export default Dashboard;



